import Card from '@component/Card';
import { Span } from '@component/Typography';
import { debounce } from 'lodash';
import Link from 'next/link';
import { useRouter } from 'next/router';
import ListingApi from 'pages/api/listing';
import React, { useCallback, useEffect, useState } from 'react';
import Box from '../Box';
import FlexBox from '../FlexBox';
import Icon from '../icon/Icon';
import Menu from '../Menu';
import MenuItem from '../MenuItem';
import TextField from '../text-field/TextField';
import StyledSearchBox from './SearchBoxStyle';

export interface SearchBoxProps {}

const SearchBox: React.FC<SearchBoxProps> = () => {
  const [category, setCategory] = useState('All Categories');
  const [resultList, setResultList] = useState([]);
  const router = useRouter();

  const handleCategoryChange = (cat) => () => {
    setCategory(cat);
  };

  const search = debounce(async (e) => {
    const value = e.target?.value;
    if (!value) setResultList([]);
    else {
      console.log('searching...', value);
      let keyword = value;
      const { data, status } = await ListingApi.searchByParams(keyword);
      if (status !== 200) {
        return setResultList([]);
      }
      let _result = data?.data.map((item) => {
        return item.title;
      });
      setResultList(_result.slice(0, 7));
    }
  }, 500);

  const handleKeyEnter = (event) => {
    if (event.key === 'Enter') {
      router.push(`/product/search/${event.target.value}`);
      event.target.value = '';
    }
  };

  const handleSearch = useCallback((event) => {
    event.persist();
    search(event);
  }, []);

  const handleDocumentClick = () => {
    setResultList([]);
  };

  useEffect(() => {
    window.addEventListener('click', handleDocumentClick);
    return () => {
      window.removeEventListener('click', handleDocumentClick);
    };
  }, []);

  return (
    <Box position="relative" flex="1 1 0" maxWidth="670px" mx="auto">
      <StyledSearchBox>
        <Icon className="search-icon" size="18px">
          search
        </Icon>
        <TextField
          className="search-field"
          placeholder="Searching for"
          fullwidth
          onChange={handleSearch}
          onFocus={handleSearch}
          onKeyDown={handleKeyEnter}
        />
      </StyledSearchBox>

      {!!resultList.length && (
        <Card position="absolute" top="100%" py="0.5rem" width="100%" boxShadow="large" zIndex={99}>
          {resultList.map((item) => (
            <Link href={`/product/search/${item}`} key={item + Math.random()}>
              <MenuItem key={item}>
                <Span fontSize="14px">{item}</Span>
              </MenuItem>
            </Link>
          ))}
        </Card>
      )}
    </Box>
  );
};

const categories = [
  'All Categories',
  'Car',
  'Clothes',
  'Electronics',
  'Laptop',
  'Desktop',
  'Camera',
  'Toys',
];

const dummySearchResult = ['Macbook Air 13', 'Ksus K555LA', 'Acer Aspire X453', 'iPad Mini 3'];

export default SearchBox;
