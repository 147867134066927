import IconButton from '@component/buttons/IconButton';
import Image from '@component/Image';
import { useAppContext } from '@context/app/AppContext';
import Link from 'next/link';
import React, { useState, useEffect } from 'react';
import Box from '../Box';
import Categories from '../categories/Categories';
import Container from '../Container';
import FlexBox from '../FlexBox';
import Icon from '../icon/Icon';
import MiniCart from '../mini-cart/MiniCart';
import SearchBox from '../search-box/SearchBox';
import Login from '../sessions/Login';
import Sidenav from '../sidenav/Sidenav';
import { Tiny } from '../Typography';
import StyledHeader from './HeaderStyle';
import UserLoginDialog from './UserLoginDialog';
import { useSession, getSession } from 'next-auth/react';
import { ChatApi } from 'pages/api';
import { useRouter } from 'next/router';

type HeaderProps = {
  isFixed?: boolean;
  className?: string;
};

const Header: React.FC<HeaderProps> = ({ isFixed, className }) => {
  const [open, setOpen] = useState(false);
  const router = useRouter();
  const toggleSidenav = () => setOpen(!open);
  const { state } = useAppContext();
  const { cartList } = state.cart;
  const { data: session, status } = useSession();
  const loading = status === 'loading';
  const [unreadMessages, setUnreadMessages] = useState(0);

  const fetchChats = async () => {
    const accessToken = session?.accessToken
    if (!accessToken) {
      return null;
    }
    try {
      const { status, data } = await ChatApi.getAllChats(accessToken);
      let unreadCount = 0;
      data.forEach((chat) => {
        unreadCount += chat.UnreadMessagesCount;
      });
      setUnreadMessages(unreadCount);
    } catch (error) {
      setUnreadMessages(0);
    }
  };

  useEffect(() => {
    if (!loading && session) {
      fetchChats();
    }
  }, [session, loading]);

  const cartHandle = (
    <FlexBox ml="20px" alignItems="flex-start">
      <IconButton bg="gray.200" p="12px">
        <Icon size="20px">bag</Icon>
      </IconButton>

      {!!cartList.length && (
        <FlexBox
          borderRadius="300px"
          bg="error.main"
          px="5px"
          py="2px"
          alignItems="center"
          justifyContent="center"
          ml="-1rem"
          mt="-9px"
        >
          <Tiny color="white" fontWeight="600">
            {cartList.length}
          </Tiny>
        </FlexBox>
      )}
    </FlexBox>
  );

  const MessageHandle = () => (
    <FlexBox ml="20px" alignItems="flex-start">
      <IconButton bg="gray.200" p="12px" onClick={() => router.push('/profile/messages')}>
        <Icon size="20px">mail</Icon>
      </IconButton>

      {!!unreadMessages && (
        <FlexBox
          borderRadius="300px"
          bg="error.main"
          px="5px"
          py="2px"
          alignItems="center"
          justifyContent="center"
          ml="-1rem"
          mt="-9px"
        >
          <Tiny color="white" fontWeight="600">
            {unreadMessages}
          </Tiny>
        </FlexBox>
      )}
    </FlexBox>
  );

  return (
    <StyledHeader className={className}>
      <Container display="flex" alignItems="center" justifyContent="space-between" height="100%">
        <FlexBox className="logo" alignItems="center" mr="1rem">
          <Link href="/">
            <a>
              <Image src="/assets/images/logo.svg" alt="logo" />
            </a>
          </Link>

          {isFixed && (
            <div className="category-holder">
              <Categories>
                <FlexBox color="text.hint" alignItems="center" ml="1rem">
                  <Icon>categories</Icon>
                  <Icon>arrow-down-filled</Icon>
                </FlexBox>
              </Categories>
            </div>
          )}
        </FlexBox>

        <FlexBox justifyContent="center" flex="1 1 0">
          <SearchBox />
        </FlexBox>

        <FlexBox className="header-right" alignItems="center">
          {/* Redirect to login if not logged in */}
          {!session && (
            <UserLoginDialog
              handle={
                <IconButton ml="1rem" bg="gray.200" p="8px">
                  <Icon size="28px">user</Icon>
                </IconButton>
              }
            >
              <Box>
                <Login />
              </Box>
            </UserLoginDialog>
          )}
          {/* Redirect to Profile if logged in */}
          {session && (
            <>
              <Link href="/profile">
                <IconButton ml="1rem" bg="teakita.main" p="8px">
                  <Icon size="28px" color="inherit">
                    user-white
                  </Icon>
                </IconButton>
              </Link>
            </>
          )}

          {session && <MessageHandle />}

          <Sidenav
            handle={cartHandle}
            position="right"
            open={open}
            width={380}
            toggleSidenav={toggleSidenav}
          >
            <MiniCart toggleSidenav={toggleSidenav} />
          </Sidenav>
        </FlexBox>
      </Container>
    </StyledHeader>
  );
};

export default Header;
