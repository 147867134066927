import axios from 'axios';

const ListingApi = {
  getUserListings: async (token, userId) => {
    try {
      const response = await axios.get(
        `${process.env.API_URL}/api/v1.0/user/my-listings`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response;
    } catch (error) {
      console.log(error);
    }
  },
  createListing: async (token, listing) => {
    try {
      const response = await axios.post(`${process.env.API_URL}/api/v1.0/listing`, listing, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response;
    } catch (error) {
      console.log(error);
    }
  },
  getListing: async (token, id) => {
    try {
      const response = await axios.get(`${process.env.API_URL}/api/v1.0/listing/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response;
    } catch (error) {
      console.log(error);
    }
  },
  patchListing: async (token, id, value) => {
    try {
      const response = await axios.patch(
        `${process.env.API_URL}/api/v1.0/listing/${id}`,
        value,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response;
    } catch (error) {
      console.log(error);
    }
  },
  deleteImage: async (token, id) => {
    try {
      const response = await axios.delete(
        `${process.env.API_URL}/api/v1.0/listing/file/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response;
    } catch (error) {
      console.log(error);
    }
  },
  deleteListing: async (token, id) => {
    try {
      // await axios.delete('')
    } catch (error) {
      console.log(error);
    }
  },
  uploadImage: async (token, file) => {
    try {
      const response = await axios.post(
        `${process.env.API_URL}/api/v1.0/listing/upload`,
        file,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': file.type,
          },
        }
      );
      return response;
    } catch (error) {
      console.log(error);
    }
  },
  getListings: async (pageSize?, pageNumber?, loggedInUserId = null) => {
    try {
      const response = await axios.get(`${process.env.API_URL}/api/v1.0/listing`, {
        params: {
          pageSize,
          pageNumber,
          loggedInUserId,
        },
      });
      return response;
    } catch (error) {
      console.log(error);
    }
  },
  searchByParams: async (
    keyword?,
    minPrice?,
    maxPrice?,
    condition?,
    pageSize?,
    pageNumber?,
    sellerUserId = null,
    averageRating = null,
    deliveryType = null,
    loggedInUserId = null
  ) => {
    try {
      const response = await axios.get(`${process.env.API_URL}/api/v1.0/listing`, {
        params: {
          conditionId: condition,
          keyword,
          minPrice,
          maxPrice,
          pageSize,
          pageNumber,
          sellerUserId,
          averageRating,
          deliveryType,
          loggedInUserId,
        },
      });
      return response;
    } catch (error) {
      console.log(error.message);
    }
  },
  addToWishList: async (token, listingId) => {
    try {
      const response = await axios.post(
        `${process.env.API_URL}/api/v1.0/listing/wishlist`,
        { listingId },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response;
    } catch (error) {
      return error;
    }
  },
  removeFromWishList: async (token, listingId) => {
    try {
      const response = await axios.delete(`${process.env.API_URL}/api/v1.0/listing/wishlist`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: {
          listingId,
        },
      });
      return response;
    } catch (error) {
      return error;
    }
  },
  getMyWishList: async (token, pageSize?, pageNumber?) => {
    try {
      const response = await axios.get(`${process.env.API_URL}/api/v1.0/listing/wishlist/me`, {
        params: {
          pageSize,
          pageNumber,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response;
    } catch (error) {
      return error;
    }
  },
};

export default ListingApi;
