const navigations = [
  {
    icon: 'home',
    title: 'Indoor',
    href: '/product/search/indoor',
    menuComponent: 'MegaMenu1',
    menuData: {
      categories: [
        {
          title: 'Bed Room',
          href: '/product/search/indoor',
          subCategories: [
            {
              title: 'Beds',
              href: '/product/search/beds',
              imgUrl: '/assets/images/products/categories/shirt.png',
            },
            {
              title: 'Wardrobes',
              href: '/product/search/wardrobes',
              imgUrl: '/assets/images/products/categories/t-shirt.png',
            },
            {
              title: 'Dressers',
              href: '/product/search/dressers',
              imgUrl: '/assets/images/products/categories/pant.png',
            },
            {
              title: 'Nightstand',
              href: '/product/search/nightstand',
              imgUrl: '/assets/images/products/categories/t-shirt.png',
            },
            {
              title: 'Chest box',
              href: '/product/search/chest-box',
              imgUrl: '/assets/images/products/categories/t-shirt.png',
            },
            {
              title: 'Mirror',
              href: '/product/search/mirror',
              imgUrl: '/assets/images/products/categories/t-shirt.png',
            },
            {
              title: 'Study Table',
              href: '/product/search/study-table',
              imgUrl: '/assets/images/products/categories/t-shirt.png',
            },
            {
              title: 'Chest of Drawers',
              href: '/product/search/chest-of-drawers',
              imgUrl: '/assets/images/products/categories/t-shirt.png',
            },
            {
              title: 'Dressing Table',
              href: '/product/search/dressing-table',
              imgUrl: '/assets/images/products/categories/t-shirt.png',
            },
          ],
        },
        {
          title: 'Dining Romm',
          href: '/product/search/accessories',
          subCategories: [
            {
              title: 'Dinning Table',
              href: '/product/search/dinning-table',
              imgUrl: '/assets/images/products/categories/belt.png',
            },
            {
              title: 'Dinning Chairs',
              href: '/product/search/dinning-chairs',
              imgUrl: '/assets/images/products/categories/hat.png',
            },
            {
              title: 'Buffets',
              href: '/product/search/buffets',
              imgUrl: '/assets/images/products/categories/watch.png',
            },
            {
              title: 'Bar Table',
              href: '/product/search/bar-table',
              imgUrl: '/assets/images/products/categories/hat.png',
            },
            {
              title: 'Bar Bar',
              href: '/product/search/bar-chair',
              imgUrl: '/assets/images/products/categories/hat.png',
            },
            {
              title: 'Mirrors',
              href: '/product/search/mirrors',
              imgUrl: '/assets/images/products/categories/hat.png',
            },
          ],
        },
        {
          title: 'Living Room',
          href: '/product/search/living-room',
          subCategories: [
            {
              title: 'Sofas',
              href: '/product/search/sofas',
              imgUrl: '/assets/images/products/categories/sneaker.png',
            },
            {
              title: 'Loungers',
              href: '/product/search/loungers',
              imgUrl: '/assets/images/products/categories/hat.png',
            },
            {
              title: 'Sideboards',
              href: '/product/search/sideboards',
              imgUrl: '/assets/images/products/categories/hat.png',
            },
            {
              title: 'Daybeds',
              href: '/product/search/daybeds',
              imgUrl: '/assets/images/products/categories/hat.png',
            },
            {
              title: 'Side Tables',
              href: '/product/search/side-tables',
              imgUrl: '/assets/images/products/categories/hat.png',
            },
            {
              title: 'Display Cabinet',
              href: '/product/search/display-cabinet',
              imgUrl: '/assets/images/products/categories/hat.png',
            },
            {
              title: 'Console Table',
              href: '/product/search/console-table',
              imgUrl: '/assets/images/products/categories/hat.png',
            },
            {
              title: 'Credenzas',
              href: '/product/search/credenzas',
              imgUrl: '/assets/images/products/categories/hat.png',
            },
            {
              title: 'TV Console',
              href: '/product/search/tv-console',
              imgUrl: '/assets/images/products/categories/sandal.png',
            },
            {
              title: 'Coffee Tables',
              href: '/product/search/Formal',
              imgUrl: '/assets/images/products/categories/shirt.png',
            },
          ],
        },
        {
          title: 'Kitchen',
          href: '/product/search/kitchen',
          subCategories: [
            {
              title: 'Cabinets',
              href: '/product/search/cabinets',
              imgUrl: '/assets/images/products/categories/shirt.png',
            },
            {
              title: 'Appliances',
              href: '/product/search/appliances',
              imgUrl: '/assets/images/products/categories/t-shirt.png',
            },
          ],
        },
        {
          title: 'Bathroom',
          href: '/product/search/bathroom',
          subCategories: [
            {
              title: 'Cabinets',
              href: '/product/search/cabinets',
              imgUrl: '/assets/images/products/categories/belt.png',
            },
            {
              title: 'Organizers',
              href: '/product/search/organizers',
              imgUrl: '/assets/images/products/categories/hat.png',
            },
            {
              title: 'Accessories',
              href: '/product/search/accessories',
              imgUrl: '/assets/images/products/categories/watch.png',
            },
          ],
        },
        {
          title: 'Office',
          href: '/products/search/office',
          subCategories: [
            {
              title: 'Tables',
              href: '/product/search/tables',
              imgUrl: '/assets/images/products/categories/belt.png',
            },
            {
              title: 'chairs',
              href: '/product/search/chairs',
              imgUrl: '/assets/images/products/categories/hat.png',
            },
            {
              title: 'Cabinets',
              href: '/product/search/cabinets',
              imgUrl: '/assets/images/products/categories/watch.png',
            },
            {
              title: 'Organizers',
              href: '/product/search/organizers',
              imgUrl: '/assets/images/products/categories/hat.png',
            },
          ],
        },
      ],
      rightImage: {
        imgUrl: '/assets/images/promotion/offer-1.png',
        href: '/sale-page-1',
      },
    },
  },
  {
    icon: 'sun',
    title: 'Outdoor',
    href: '/product/search/outdoor',
    menuComponent: 'MegaMenu1',
    menuData: {
      categories: [
        {
          title: 'Outdoor',
          href: '/product/search/outdoor',
          subCategories: [
            {
              title: 'Tables',
              href: '/product/search/tables',
              imgUrl: '/assets/images/products/categories/shirt.png',
            },
            {
              title: 'Chairs',
              href: '/product/search/chairs',
              imgUrl: '/assets/images/products/categories/t-shirt.png',
            },
            {
              title: 'Loungers',
              href: '/product/search/loungers',
              imgUrl: '/assets/images/products/categories/pant.png',
            },
            {
              title: 'Gazebos',
              href: '/product/search/gazebos',
              imgUrl: '/assets/images/products/categories/t-shirt.png',
            },
          ],
        },
      ],
      bottomImage: {
        imgUrl: '/assets/images/promotion/offer-5.png',
        href: '/',
      },
    },
  },
  {
    icon: 'board',
    title: 'Others',
    href: '/product/search/lighting',
    menuComponent: 'MegaMenu1',
    menuData: {
      categories: [
        {
          title: 'Lighting',
          href: '/product/search/lighting',
        },
        {
          title: 'Decorations',
          href: '/product/search/decorations',
        },
        {
          title: 'Accessories',
          href: '/product/search/accessories',
        },
        {
          title: 'Curtains',
          href: '/product/search/curtains',
        },
        {
          title: 'Carpets',
          href: '/product/search/carpets',
        },
        {
          title: 'Custom Furniture',
          href: '/product/search/custom-furniture',
        },
      ],
      bottomImage: {
        imgUrl: '/assets/images/promotion/offer-5.png',
        href: '/',
      },
    },
  },
];

export default navigations;
